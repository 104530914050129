const EVENT_TASK_ENDPOINTS = {
    eventTasks: '/eventTasks',
};

const USER_PROFILE_API = {
    userProfile: '/ioct-api/v1/user',
    userGroups: '/ioct-api/v1/getUserGroups',
    groupsMapping: '/ioct-api/v1/getGroups',
    updateUserGroup: '/ioct-api/v1/user/activeGroup/switch',
    userList: '/ioct-api/v1/users',
};

const AIRCRAFT_API = {
    getSubfleetGeneralDetails: '/ioct-api/v1/subfleet/generalDetails',
    getSubfleetSummaryDetails: '/ioct-api/v1/subfleet/summaryDetails',
    getSubfleetAircraftPerformance: '/ioct-api/v1/subfleet/aircraftPerformance',
    getAircraftInfo: '/ioct-api/v1/aircraftDetails/aircraftInfo',
};

const EVENTS_API = {
    getMCiLogEvents: '/ioct-api/v1/events/mcilog',
    getMcilogShiftViewEvents: '/ioct-api/v2/events/mcilog/shiftView',
    setting: '/ioct-api/v2/event/setting',
    event: '/ioct-api/v1/event',
    eventV2: '/ioct-api/v2/event',
    closeEvent: '/ioct-api/v1/closeEvent',
    reopenEvent: '/ioct-api/v1/event/reopen',
    cancelEvent: '/ioct-api/v1/event/cancel',
    getEvents: '/ioct-api/v1/getEvents',
    getEventsByPost: '/ioct-api/v1/events/ioc',
    getLMTTEvent: '/ioct-api/v2/getEvents',
    exportEvents: '/ioct-api/v1/exportEvents',
    exportLmttEvents: '/ioct-api/v2/exportLmttEvents',
    coeExportEvent: '/ioct-api/v3/aog/coeEvent/exportEvent',
    updateEts: '/ioct-api/v1/updateETS',
    updateAts: '/ioct-api/v1/updateATS',
    getConflictEventTasks: 'ioct-api/v1/etsCompare',
    getRegList: '/ioct-api/v1/event/reglist',
    getAirportList: '/ioct-api/v1/airport',
    getMaintenance: '/ioct-api/v2/getMaintenances',
    getFleetSubtypeList: '/ioct-api/v1/getFleetSubtypeList',
    getNextFlightDeparture: '/ioct-api/v1/getNextDeptFlight',
    updateFollowUpStatus: '/ioct-api/v1/eventFollowUp',
    updateEventStatus: '/ioct-api/v1/updateEvent',
    updateEventTaskStatus: '/ioct-api/v1/updateEventTaskStatus',
    getOwnerList: '/ioct-api/v1/getCategoryGroups',
    getPackages: '/ioct-api/v2/getPackages',
    saveEventNote: '/ioct-api/v1/saveEventNote',
    saveEventTaskNote: '/ioct-api/v1/saveEventTaskNote',
    getEventReport: '/ioct-api/v1/report/iocevent',
    getHandoverNotePDF: '/ioct-api/v1/report/handoverNote',
    getAircraftType: '/ioct-api/v3/aircraft/list',
    getActionItemType: '/ioct-api/v1/actionItem/setting',
    saveIOCAlert: '/ioct-api/v1/event/report/iocalert/save',
    getIOCAlertById: '/ioct-api/v1/event/report/iocalert',
    sendIOCAlert: '/ioct-api/v1/event/report/iocalert/send',
    getProperties: '/ioct-api/v1/properties',
    updateEventDetails: '/ioct-api/v1/event/details',
    declareAog: '/ioct-api/v1/event/aog/declare',
    getEventMedia: '/ioct-api/v1/event/media',
};

const EVENT_TASK_API = {
    eventTasks: `/ioct-api/v1${EVENT_TASK_ENDPOINTS.eventTasks}`,
};
const ACTION_ITEM_DETAIL_API = {
    eventTasks: '/ioct-api/v1/eventTask',
};

const REPORT_API = {
    getReportCard: '/ioct-api/v1/event/reportCard',
    sendAogReport: '/ioct-api/v1/sendAogReport',
    generateHandoverReport: '/ioct-api/v1/report/handover',
    generateEventsReport: '/ioct-api/v1/report/mcevent',
    generateAogStatusReport: '/ioct-api/v1/report/aogStatusReport',
};

const ISSUE_SUMMARY_API = {
    getAtaCodes: '/ioct-api/v1/getAtaCodes',
    updateIssueLog: '/ioct-api/v1/issueLog',
    getIssueLogs: '/ioct-api/v1/issueLogs',
    removeMcLog: '/ioct-api/v1/issueLog/delete',
};

const EVENT_TASK_HISTORY_LOGS_API = {
    getEventTaskHistoryLogs: '/ioct-api/v1/getEventTaskSystemLogs',
};

const AXIOS_ERR_CODE_TIMEOUT = 'ECONNABORTED';

const FLIGHTS_API = {
    getFlights: '/ioct-api/v1/flight',
    getDepartureFlights: '/ioct-api/v1/flight/nextDepts',
    getFlightPortInfo: 'ioct-api/v1/flight/portInfo',
};

const ADD_API = {
    getAdd: '/ioct-api/v2/getDefects',
};

const DISMESSAGE_API = {
    sendDisMessage: '/ioct-api/v1/sendDisMessage',
};

const HTTP_REQUEST_TYPE = {
    get: 'GET',
    post: 'POST',
    put: 'PUT',
    delete: 'DELETE',
};

const HTTP_RESPONSE_TYPE = {
    success: 'SUCCESS',
    error: 'ERROR',
};

const FILES_API = {
    uploadFiles: '/ioct-api/v1/uploadFiles',
    downloadFile: '/ioct-api/v1/downloadFile',
    downloadFiles: '/ioct-api/v1/downloadFiles',
};

const CHAT_API = {
    connectWebSocket: '/comm/ws',
    message: '/comm/1.0/message',
    messageAttachment: '/comm/1.0/message/resource',
    getMetaDataByAudience: '/comm/1.0/audience/getMeta',
    getFlightByChatUfis: '/ioct-api/v1/getFlightByChatUfis',
    markAllChatRead: '/comm/1.0/user/read',
};

const PRIORITY_FLIGHT_API = {
    getDefinitionList: '/ioct-api/v1/priorityFlight/definition',
    getFlightList: '/ioct-api/v1/priorityFlight/list',
    sendFlightList: '/ioct-api/v1/priorityFlight/mail',
    createDefinition: '/ioct-api/v1/priorityFlight/definition',
    deleteDefinition: '/ioct-api/v1/priorityFlight/definition/delete',
};

const GANTT_API = {
    getFlightEvent: '/ioct-api/v1/flight/getEvents',
    getFlightGeneralDetails: '/ioct-api/v1/flight/getFlightDetails',
    getMaintBarByLegNo: '/ioct-api/v1/maintDetails/getMaintBarByLegNo',
    getAircraftGeneralDetails: '/ioct-api/v1/aircraftDetails/general',
    getMaintBarEventsByLegNo: 'ioct-api/v2/maintDetails/getMaintBarEventsByLegNo',
    getAircraftDetails: '/ioct-api/v1/aircraftDetails/aircraft',
    getAircraftADDs: '/ioct-api/v1/aircraftDetails/defects',
    getFlightADDs: '/ioct-api/v1/flight/getAddDetails',
    getFlightAirport: '/ioct-api/v1/flightDetails/airport',
    getFlightPlanning: '/ioct-api/v1/flightDetails/flightPlanning',
    getFlightCargo: '/ioct-api/v1/flight/cargo',
    getFlightCrew: '/ioct-api/v1/flight/crews',
    getFlightPax: '/ioct-api/v1/flight/paxCounts',
    getFlightSchedule: '/ioct-api/v1/flight/schedule',
    getFlightEventsAndConstraints: '/ioct-api/v1/aircraftDetails/events',
    getFlightCargoPTS: '/ioct-api/v1/flight/cargopts',
    getMaintBarHistory: '/ioct-api/v1/maintDetails/getMaintBarHistory',
    getAllGroundTimeTasks: '/ioct-api/v1/maintDetails/getAllGroundTimeTasks',
    getMaintenanceDueTolerance: '/ioct-api/v1/maintDetails/getMaintenanceDueTolerance',
    getFleetSubtypeList: '/ioct-api/v1/getFleetSubtypeList',
};

const DASHBOARD_API = {
    getLiveDashboard: '/ioct-api/v1/performance/live',
    getDailyDashboard: '/ioct-api/v1/performance/daily',
};

const BASIC_API = {
    home: '/',
    healthCheck: '/_health',
    authRedirectUrlInReact: '/redirect',
    authRedirectUrlInExpress: '/ad_auth',
    signIn: '/signin',
    logout: '/logout',
    configFile: '/config.js',
};

const DOM_API_URL = {
    getAllAircraftType: '/ioct-api/v1/dom/getAllAircraftType',
    getDomCrews: '/ioct-api/v1/dom/getDomCrews',
};

const IOC_ALERT_URL = {
    deleteAlertDraft: '/ioct-api/v1/eventTask/delete',
};

const COMMON_API = {
    getTimeOffset: '/ioct-api/v1/airport/timezoneOffset',
    getRecipients: '/ioct-api/v1/emailList',
};

const COE_CRD_API_URL = {
    getCrdRulesList: '/ioct-api/v1/crd/rules/filter',
    createTrigger: '/ioct-api/v1/crd/rules',
    getCrdRule: '/ioct-api/v1/crd/rule',
    getAircraftTypes: '/ioct-api/v1/config/getAircraftTypes',
};

const MC_WORK_REQUEST_API = {
    wrq: '/ioct-api/v1/events/mcilog/wrq',
};

const ALERT_MODULE_API = {
    getAlertsList: '/ioct-api/v1/alert/filter',
};

const OBS_API = {
    sendMessage: '/ioct-api/v1/obs/message',
    getMessages: '/ioct-api/v2/obs/messages',
    getObsRecipients: '/ioct-api/v1/obs/emailList',
};

const CHECKLIST_API = {
    createChecklistEvent: '/ioct-api/v3/checklist/event/create',
    getChecklistEvents: '/ioct-api/v3/checklist/event/all',
    getChecklistEvent: '/ioct-api/v3/checklist/event',
    updateChecklistEvent: '/ioct-api/v3/checklist/event/update',
    getChecklistsById: '/ioct-api/v3/checklist/checklists',
    updateChecklist: '/ioct-api/v3/checklist/checklists',
    getChecklistTaskById: '/ioct-api/v3/checklist/tasks',
    updateTask: '/ioct-api/v3/checklist/tasks/update',
    sendMeeting: '/ioct-api/v3/checklist/tasks/teamsMeetings',
    getEventById: '/ioct-api/v3/checklist/event',
    reportChecklistIRopsPDF: '/ioct-api/v3/checklist/report/irops',
    deleteChecklistAndTask: '/ioct-api/v3/checklist/checklists/delete',
    copyChecklist: '/ioct-api/v3/checklist/checklists/copy',
    addNewTasks: '/ioct-api/v3/checklist/tasks/create',
    addNewChecklist: '/ioct-api/v3/checklist/checklists/new',
    getComments: '/ioct-api/v3/checklist/comments',
    saveComment: '/ioct-api/v3/checklist/comments',
    deleteComment: '/ioct-api/v3/checklist/comments/delete',
    updateSequence: '/ioct-api/v3/checklist/checklists/rearrange',
    updateQNA: '/ioct-api/v3/checklist/event/updateQNA',
    getReminderInfo: '/ioct-api/v3/checklist/event/reminderInfo',
    closeTasks: '/ioct-api/v3/checklist/tasks/close',
    updateEventStatus: '/ioct-api/v3/checklist/event/updateStatus',
    updateEventDraft: '/ioct-api/v3/checklist/event/draft',
};

const CHECKLIST_MANAGEMENT_API = {
    checklistTemplate: '/ioct-api/v3/checklist/template/saveChecklistTemplate',
    getChecklistTemplates: '/ioct-api/v3/checklist/template',
    saveTaskTemplate: '/ioct-api/v3/checklist/template/saveTaskTemplate',
    deleteTemplate: '/ioct-api/v3/checklist/template/delete',
};
const CHECKLIST_DASHBOARD_API = {
    getChecklistStatistics: '/ioct-api/v3/checklist/dashboard/statistic',
};

enum ApiStatusCode {
    FORBIDDEN = 403,
}

export {
    EVENT_TASK_ENDPOINTS,
    ApiStatusCode,
    CHAT_API,
    AIRCRAFT_API,
    DISMESSAGE_API,
    AXIOS_ERR_CODE_TIMEOUT,
    HTTP_REQUEST_TYPE,
    HTTP_RESPONSE_TYPE,
    USER_PROFILE_API,
    EVENTS_API,
    FLIGHTS_API,
    ADD_API,
    EVENT_TASK_API,
    ISSUE_SUMMARY_API,
    FILES_API,
    REPORT_API,
    EVENT_TASK_HISTORY_LOGS_API,
    GANTT_API,
    DASHBOARD_API,
    BASIC_API,
    ACTION_ITEM_DETAIL_API,
    PRIORITY_FLIGHT_API,
    DOM_API_URL,
    IOC_ALERT_URL,
    COMMON_API,
    COE_CRD_API_URL,
    MC_WORK_REQUEST_API,
    ALERT_MODULE_API,
    OBS_API,
    CHECKLIST_API,
    CHECKLIST_MANAGEMENT_API,
    CHECKLIST_DASHBOARD_API,
};
