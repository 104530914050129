import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    downloadFile,
    downloadFiles,
    exportFile,
    exportLmttFile,
    uploadFile,
    exportMCPFile,
} from '../services/manageFiles';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { OwnerType } from '../components/common/FileUpload';
import { GenPdfInfo, EventStatusType } from '../constants/constants';
import { createdDate } from '../components/reportGenerate/ReportGenInfo';
import { getStatusReportBlob } from '../components/reportGenerate/ReportGenBase';
import { IPdfType } from '../interface/Report';
import { resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';
import { ActionItemStatusType, FilterAndSortOptions } from './coeEventSlice';
import UserInfo from '../interface/UserInfo';

export interface Files {
    owner: OwnerType;
    ownerId: number;
    uploadFiles: File[];
}

type FileSlice = {
    isFileLoading: boolean;
    thumbnailItems: ThumbnailItem[];
    errorObject: ApiErrorObj;
    statusReport: ThumbnailItem;
    isReportLoading: boolean;
};
export interface ThumbnailItem {
    active?: boolean;
    id: number;
    thumbnail: string;
    fileName: string;
    fileType?: string;
    key?: string;
    bucket?: string;
    createdAt?: string;
    updatedAt?: string;
    createdBy?: number | UserInfo;
    updatedBy?: number | UserInfo;
    ownerId?: string | number;
    owner?: number;
}
const initialState: FileSlice = {
    isFileLoading: false,
    thumbnailItems: [],
    errorObject: null,
    statusReport: null,
    isReportLoading: false,
};

export interface ExportOptions extends FilterAndSortOptions {
    actionItem: boolean;
    eventIds: number[];
}

export interface exportMCPFileRequest {
    eventTypeIds: number[];
    start: string;
    end: string;
}
export const uploadReportThunk = createAsyncThunk<
    ThumbnailItem,
    { pdfSource: IPdfType },
    { state: RootState; rejectValue: ApiErrorObj }
>('file/uploadReport', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId, currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/uploadFiles')) {
        return rejectWithValue(ForbiddenObj);
    }

    const { pdfSource } = params;
    const pdfBlob = (await getStatusReportBlob(pdfSource)) as Blob;
    const formData = new FormData();
    const reportFile = new File([pdfBlob], `${GenPdfInfo.title} ${createdDate()}.pdf`, { type: 'application/pdf' });
    formData.append('userId', userId);
    formData.append('uploadFiles', reportFile);

    const [err, data] = await uploadFile(formData);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as ThumbnailItem;
});

export const downloadBlob = ({ blob, fileName }: { blob: Blob; fileName: string }) => {
    const fileDownloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = fileDownloadUrl;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(fileDownloadUrl);
};

export const uploadFilesThunk = createAsyncThunk<
    ThumbnailItem[],
    Files,
    { state: RootState; rejectValue: ApiErrorObj; signal: AbortSignal }
>('file/uploadFiles', async (params, { getState, rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
        source.cancel();
    });
    const { userProfile } = getState().userProfile;
    const { userId, currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/uploadFiles')) {
        return rejectWithValue(ForbiddenObj);
    }
    const { uploadFiles, owner, ownerId } = params;
    const formData = new FormData();
    formData.append('userId', userId);

    if (owner) {
        formData.append('owner', owner);
    }

    if (ownerId) {
        formData.append('ownerId', ownerId.toString());
    }

    uploadFiles.forEach((filePath) => {
        formData.append('uploadFiles', filePath);
    });

    const [err, data] = await uploadFile(formData, source.token);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as ThumbnailItem[];
});

export const downloadFileThunk = createAsyncThunk<
    { blob: Blob; fileName: string },
    { id: number; customName?: string },
    { state: RootState; rejectValue: ApiErrorObj }
>('file/downloadFile', async ({ id, customName }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/downloadFile')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, blob, fileName] = await downloadFile(id);

    if (err || (!fileName && !customName)) {
        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        if (!fileName && !customName) {
            return rejectWithValue({
                statusCode: 0,
                title: 'File Not Found',
                detail: 'File Not Exist',
                caseId: -2,
            });
        }
    }

    return { blob, fileName: customName || fileName };
});

export const downloadFilesThunk = createAsyncThunk<
    { blob: Blob; fileName: string },
    { ids: number[]; customName?: string },
    { state: RootState; rejectValue: ApiErrorObj }
>('file/downloadFiles', async ({ ids, customName }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/downloadFiles')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, blob, fileName] = await downloadFiles(ids);

    if (err || (!fileName && !customName)) {
        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        if (!fileName && !customName) {
            return rejectWithValue({
                statusCode: 0,
                title: 'File Not Found',
                detail: 'File Not Exist',
                caseId: -2,
            });
        }
    }

    return { blob, fileName: customName || fileName };
});

export const exportFileThunk = createAsyncThunk<
    { blob: Blob },
    { status: string; subtypeStatus: string; startDate: string; endDate: string },
    { state: RootState; rejectValue: ApiErrorObj }
>('file/exportFile', async ({ status, subtypeStatus, startDate, endDate }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/exportEvents')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, blob] = await exportFile({ status, subtypeStatus, startDate, endDate });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { blob };
});

export const exportLmttFileThunk = createAsyncThunk<
    { blob: Blob },
    ExportOptions,
    { state: RootState; rejectValue: ApiErrorObj }
>('file/exportLmttFile', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/exportLmttEvents')) {
        return rejectWithValue(ForbiddenObj);
    }

    const { eventStatus, actionItemStatus, fleetSubtype } = params || {};
    const updatedEventStatus =
        eventStatus && eventStatus.length > 0 && eventStatus.find((item) => item === EventStatusType.outstanding)
            ? eventStatus.concat(EventStatusType.open)
            : eventStatus;

    const updatedActionItemStatus =
        actionItemStatus &&
        actionItemStatus.length > 0 &&
        actionItemStatus.find((item) => item === ActionItemStatusType.outstanding)
            ? actionItemStatus.concat(ActionItemStatusType.open)
            : actionItemStatus;

    const updatedFleetSubtypes =
        (fleetSubtype &&
            fleetSubtype.map((item) => {
                return item && item.slice(-3);
            })) ||
        [];

    const updatedParams = {
        ...params,
        fleetSubtype: updatedFleetSubtypes,
        eventStatus: updatedEventStatus || [],
        actionItemStatus: updatedActionItemStatus || [],
    };

    const [err, blob] = await exportLmttFile(updatedParams);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { blob };
});

export const exportMCPFileThunk = createAsyncThunk<
    { blob: Blob; header: { [key: string]: string } },
    exportMCPFileRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('file/exportMCPFile', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/aog/coeEvent/exportEvent')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, blob, header] = await exportMCPFile(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    const serializedHeader = {
        'content-disposition': header?.['content-disposition'] ?? '',
    };

    return { blob, header: serializedHeader };
});

export const resetState = createAction('file/resetState');
export const fileSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(uploadFilesThunk.pending, (state) => {
            state.thumbnailItems = [];
            state.isFileLoading = true;
        });
        builder.addCase(uploadFilesThunk.fulfilled, (state, { payload }) => {
            state.thumbnailItems = payload;
            state.isFileLoading = false;
        });
        builder.addCase(uploadFilesThunk.rejected, (state) => {
            state.thumbnailItems = [];
            state.isFileLoading = false;
        });
        builder.addCase(downloadFileThunk.pending, (state) => {
            state.errorObject = null;
            state.isReportLoading = true;
        });
        builder.addCase(downloadFileThunk.fulfilled, (state, { payload }) => {
            state.errorObject = null;
            const { blob, fileName } = payload;
            downloadBlob({ blob, fileName: fileName });
            state.isReportLoading = false;
        });
        builder.addCase(downloadFileThunk.rejected, (state, { payload }) => {
            state.errorObject = payload;
            state.isReportLoading = false;
        });
        builder.addCase(downloadFilesThunk.pending, (state) => {
            state.isReportLoading = true;
        });
        builder.addCase(downloadFilesThunk.fulfilled, (state, { payload }) => {
            const { blob, fileName } = payload;
            downloadBlob({ blob, fileName: fileName });
            state.isReportLoading = false;
        });
        builder.addCase(downloadFilesThunk.rejected, (state, { payload }) => {
            state.isReportLoading = false;
        });
        builder.addCase(exportFileThunk.pending, (state) => {
            state.errorObject = null;
            state.isFileLoading = true;
        });
        builder.addCase(exportFileThunk.fulfilled, (state, { payload }) => {
            state.errorObject = null;
            const { blob } = payload;
            downloadBlob({ blob, fileName: 'eventReport.csv' });
            state.isFileLoading = false;
        });
        builder.addCase(exportLmttFileThunk.rejected, (state, { payload }) => {
            state.errorObject = payload;
            state.isFileLoading = false;
        });
        builder.addCase(exportLmttFileThunk.pending, (state) => {
            state.errorObject = null;
            state.isFileLoading = true;
        });
        builder.addCase(exportLmttFileThunk.fulfilled, (state, { payload }) => {
            state.errorObject = null;
            const { blob } = payload;
            downloadBlob({ blob, fileName: 'eventReport.csv' });
            state.isFileLoading = false;
        });
        builder.addCase(exportMCPFileThunk.rejected, (state, { payload }) => {
            state.errorObject = payload;
            state.isFileLoading = false;
        });
        builder.addCase(exportMCPFileThunk.pending, (state) => {
            state.errorObject = null;
            state.isFileLoading = true;
        });
        builder.addCase(exportMCPFileThunk.fulfilled, (state, { payload }) => {
            state.errorObject = null;
            const { blob, header } = payload;
            const encodedFileName = header['content-disposition']?.split('attachment;filename=')[1];
            const fileName = encodedFileName ? decodeURI(encodedFileName) : 'MCP Deferral Records.csv';
            downloadBlob({ blob, fileName });
            state.isFileLoading = false;
        });
        builder.addCase(exportFileThunk.rejected, (state, { payload }) => {
            state.errorObject = payload;
            state.isFileLoading = false;
        });
        builder.addCase(uploadReportThunk.pending, (state, { payload }) => {
            state.statusReport = null;
            state.isReportLoading = true;
        });
        builder.addCase(uploadReportThunk.fulfilled, (state, { payload }) => {
            state.statusReport = payload;
            state.isReportLoading = false;
        });
        builder.addCase(uploadReportThunk.rejected, (state) => {
            state.statusReport = null;
            state.isReportLoading = false;
        });
        builder.addDefaultCase((state, action) => {
            state.thumbnailItems = [];
        });
    },
});

export const selectedFile = (state: RootState) => state.file;
export default fileSlice.reducer;
