import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import {
    getReminderInfo,
    getChecklistsById,
    getChecklistEvents,
    getChecklistTaskById,
    updateTask,
    sendMeeting,
    updateEvent,
    createEvent,
    updateEventDraft,
    getEventById,
    reportChecklistIRopsPDF,
    deleteIRopsChecklistAndTask,
    copyTeamMeeting,
    updateChecklist,
    addNewTasks,
    addNewChecklist,
    saveComment,
    deleteComments,
    getComments,
    updateSequence,
    updateQNA,
    closeTasks,
    updateEventStatus,
} from '../services/checklist';
import { downloadBlob } from './fileSlice';
import { sendObsMessage } from '../services/obs';

import {
    IChecklistEventItem,
    IChecklistEventsResponse,
    ICheckListEventsFilterRequest,
    IReminderInfoResponse,
    IChecklistItem,
    IChecklistsRequest,
    IChecklistsResponse,
    ITaskFormData,
    IChecklistTaskResponse,
    IChecklistTaskRequest,
    IUpdateTaskRequest,
    ICreateTeamsMeetingRequest,
    IEditIRopsMeetingTaskFieldsRequest,
    IGetEventByIdRequest,
    IGetEventByIdResponse,
    IEventDetail,
    ITask,
    IROpsMeetingResponseFields,
    IReportIrOpsRequestParams,
    IRopsDeleteRequest,
    ICopyMeetingFieldsRequest,
    IUpdateChecklistRequest,
    IUpdateChecklistResponse,
    ISendMeetingResponse,
    IAddNewTasksRequest,
    IChecklistEventFormData,
    IGenericForm,
    IEventFormSubmitRequest,
    IAddNewGenericChecklistRequest,
    IAddNewIROPSChecklistRequest,
    IChecklistTask,
    ISaveComments,
    IDeleteComments,
    IGetComments,
    IComment,
    IGetCommentsResponse,
    IUpdateSequenceRequest,
    IUpdateQNARequest,
    ICloseTasks,
    IEventUpdateStatusRequest,
} from '../interface/Checklist';
import { ObsMessage } from '../interface/Obs';
import { ChecklistType } from '../components/checklistManagement/constants';

interface ChecklistStateProps {
    // loading
    isChecklistLoading: boolean;
    // data
    checklistEvents: IChecklistEventItem[];
    checklists: IChecklistItem[];
    selectedEvent?: IChecklistEventItem;
    eventDetail?: IEventDetail; // for detail page
    taskDetail?: ITask; // for detail page
    // form state
    checklistEventForm: IGenericForm<IChecklistEventFormData>;
    checklistForm: {
        isOpen: boolean;
        eventId?: string;
        values?: IAddNewGenericChecklistRequest;
    };
    taskForm: {
        isOpen: boolean;
        values?: ITaskFormData;
    };
    teamsMeetingForm: {
        isOpen: boolean;
        isEdit?: boolean;
        eventId?: string;
        copyChecklistId?: string;
        values?: IROpsMeetingResponseFields;
    };
    irOpsReportForm: {
        isOpen: boolean;
        values?: IReportIrOpsRequestParams;
    };
    alertDialog: {
        isOpen: boolean;
        model?: string;
        taskIds?: string[];
        checklistIds?: string[];
        commentIds?: string[];
        eventId?: string;
    };
    addTaskDialog: {
        isOpen: boolean;
        title?: string;
        checklistListIds?: string[];
        type?: ChecklistType.IROPS | ChecklistType.generic;
    };
    commentsList: IComment[];
    bombThreatChatForm: {
        isOpen?: boolean;
        isDisabled?: boolean;
        isSelectChat?: boolean;
        qna?: {
            [key: string]: string;
        };
    };
}

const initialState: ChecklistStateProps = {
    isChecklistLoading: false,
    checklistEvents: [],
    selectedEvent: undefined,
    checklists: [],
    checklistEventForm: {
        isOpen: false,
    },
    checklistForm: {
        isOpen: false,
    },
    taskForm: {
        isOpen: false,
    },
    teamsMeetingForm: {
        isOpen: false,
        isEdit: false,
    },
    irOpsReportForm: {
        isOpen: false,
    },
    alertDialog: {
        isOpen: false,
        model: '',
    },
    addTaskDialog: {
        isOpen: false,
        title: '',
        checklistListIds: [],
        type: ChecklistType.generic,
    },
    commentsList: [],
    bombThreatChatForm: {
        isOpen: false,
        isDisabled: false,
        isSelectChat: false,
    },
};

export const getReminderInfoThunk = createAsyncThunk<
    IReminderInfoResponse,
    {},
    { state: RootState; rejectValue: ApiErrorObj }
>('getReminderInfo', async (params, { getState, rejectWithValue }) => {
    const [err, data] = await getReminderInfo(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const getChecklistEventsThunk = createAsyncThunk<
    IChecklistEventsResponse,
    ICheckListEventsFilterRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('getChecklistEvents', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/event/all', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getChecklistEvents(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const getChecklistsByIdThunk = createAsyncThunk<
    IChecklistsResponse,
    IChecklistsRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('getChecklistsById', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/checklists', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getChecklistsById(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const getChecklistTaskByIdThunk = createAsyncThunk<
    IChecklistTaskResponse,
    IChecklistTaskRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('getChecklistTaskById', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/tasks', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getChecklistTaskById(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});
export const getEventByIdThunk = createAsyncThunk<
    IGetEventByIdResponse,
    IGetEventByIdRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('getEventById', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/event', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getEventById(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data;
});

export const updateTaskThunk = createAsyncThunk<
    IChecklistTaskResponse,
    IUpdateTaskRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('updateTask', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/tasks/update', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await updateTask(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const sendMeetingThunk = createAsyncThunk<
    ISendMeetingResponse,
    ICreateTeamsMeetingRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('sendMeetings', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (
        !resourceCheck(currentPermissionList, ResourceType.API, '/checklist/tasks/teamsMeetings', ResourceAction.POST)
    ) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await sendMeeting(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const sendChecklistObsMessageThunk = createAsyncThunk<
    { broadcastMessageId: string },
    ObsMessage,
    { state: RootState; rejectValue: ApiErrorObj }
>('sendChecklistObsMessage', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/obs/message', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await sendObsMessage(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const createEventThunk = createAsyncThunk<
    {},
    IEventFormSubmitRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('createChecklistEvent', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/event/create', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await createEvent(params);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const updateEventDraftThunk = createAsyncThunk<
    {},
    IEventFormSubmitRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('updateEventDraft', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/event/draft', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await updateEventDraft(params);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const updateEventThunk = createAsyncThunk<
    {},
    IEventFormSubmitRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('updateChecklistEvent', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/event/update', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await updateEvent(params);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const updateIRopsMeetingThunk = createAsyncThunk<
    {},
    IEditIRopsMeetingTaskFieldsRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('editIRopsMeeting', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/tasks/update', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await updateTask(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const reportChecklistIRopsThunk = createAsyncThunk<
    { blob: Blob | null; header: Headers },
    IReportIrOpsRequestParams,
    { state: RootState; rejectValue: ApiErrorObj }
>('reportChecklistIRops', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/report/irops', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, blob, header] = await reportChecklistIRopsPDF(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { blob, header };
});

export const deleteChecklistAndTaskThunk = createAsyncThunk<
    {},
    IRopsDeleteRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('deleteIRopsEvent', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/checklists/delete', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await deleteIRopsChecklistAndTask(params);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const copyTeamMeetingThunk = createAsyncThunk<
    {},
    ICopyMeetingFieldsRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('copyChecklist', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/checklists/copy', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await copyTeamMeeting(params);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const updateChecklistThunk = createAsyncThunk<
    IUpdateChecklistResponse,
    IUpdateChecklistRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('updateChecklist', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/checklists', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await updateChecklist(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const addNewTasksThunk = createAsyncThunk<
    {},
    IAddNewTasksRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('addTasks', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/tasks/create', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await addNewTasks(params);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const addNewChecklistThunk = createAsyncThunk<
    {},
    IAddNewGenericChecklistRequest | IAddNewIROPSChecklistRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('addNewChecklist', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/checklists/new', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await addNewChecklist(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const saveCommentThunk = createAsyncThunk<{}, ISaveComments, { state: RootState; rejectValue: ApiErrorObj }>(
    'saveComment',
    async (params, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/comments', ResourceAction.POST)) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await saveComment(params);
        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data;
    }
);

export const getCommentsThunk = createAsyncThunk<
    IGetCommentsResponse,
    IGetComments,
    { state: RootState; rejectValue: ApiErrorObj }
>('getComments', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/comments', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getComments(params);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const deleteCommentsThunk = createAsyncThunk<
    {},
    IDeleteComments,
    { state: RootState; rejectValue: ApiErrorObj }
>('deleteComments', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/comments/delete', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await deleteComments(params);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const updateSequenceThunk = createAsyncThunk<
    {},
    IUpdateSequenceRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('updateChecklistSequence', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (
        !resourceCheck(currentPermissionList, ResourceType.API, '/checklist/checklists/rearrange', ResourceAction.POST)
    ) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await updateSequence(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data;
});

export const updateQNAThunk = createAsyncThunk<{}, IUpdateQNARequest, { state: RootState; rejectValue: ApiErrorObj }>(
    'updateQNA',
    async (params, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (
            !resourceCheck(currentPermissionList, ResourceType.API, '/checklist/event/updateQNA', ResourceAction.POST)
        ) {
            return rejectWithValue(ForbiddenObj);
        }

        const [err, data] = await updateQNA(params);

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }
        return data;
    }
);

export const closeTasksThunk = createAsyncThunk<{}, ICloseTasks, { state: RootState; rejectValue: ApiErrorObj }>(
    'closeTasks',
    async (params, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/tasks/close', ResourceAction.POST)) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await closeTasks(params);
        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data;
    }
);

export const updateEventStatusThunk = createAsyncThunk<
    {},
    IEventUpdateStatusRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('updateEventStatus', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/event/updateStatus', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await updateEventStatus(params);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

const checklistSlice = createSlice({
    name: 'checklist',
    initialState,
    reducers: {
        selectEvent: (state, action: PayloadAction<string>) => {
            state.selectedEvent = state.checklistEvents.find((event) => event.eventId === action.payload);
        },
        updateChecklistEventForm: (state, action: PayloadAction<IGenericForm<IChecklistEventFormData>>) => {
            state.checklistEventForm = action.payload;
        },
        updateChecklistForm: (
            state,
            action: PayloadAction<{ isOpen: boolean; eventId?: string; values?: IAddNewGenericChecklistRequest }>
        ) => {
            state.checklistForm = action.payload;
        },
        updateTaskForm: (state, action: PayloadAction<{ isOpen: boolean; values?: ITaskFormData }>) => {
            state.taskForm = action.payload;
        },
        updateTeamsMeetingForm: (
            state,
            action: PayloadAction<{
                isOpen: boolean;
                isEdit?: boolean;
                eventId?: string;
                copyChecklistId?: string;
                values?: IROpsMeetingResponseFields;
            }>
        ) => {
            state.teamsMeetingForm = action.payload;
        },
        updateIrOpsReportForm: (
            state,
            action: PayloadAction<{
                isOpen: boolean;
                values?: IReportIrOpsRequestParams;
            }>
        ) => {
            state.irOpsReportForm = action.payload;
        },
        updateBombThreatForm: (
            state,
            action: PayloadAction<{
                isOpen?: boolean;
                isDisabled?: boolean;
                isSelectChat?: boolean;
                values?: ITaskFormData;
                qna?: {
                    [key: string]: string;
                };
            }>
        ) => {
            if (action.payload?.isOpen !== undefined) {
                state.bombThreatChatForm.isOpen = action.payload.isOpen;
            }
            if (action.payload?.isDisabled !== undefined) {
                state.bombThreatChatForm.isDisabled = action.payload.isDisabled;
            }
            if (action.payload?.isSelectChat !== undefined) {
                state.bombThreatChatForm.isSelectChat = action.payload.isSelectChat;
            }
            if (action.payload?.qna !== undefined) {
                state.bombThreatChatForm.qna = action.payload.qna;
            }
            // state.bombThreatChatForm = action.payload;
        },
        updateAlertDialog: (
            state,
            action: PayloadAction<{
                isOpen: boolean;
                model?: string;
                taskIds?: string[];
                checklistIds?: string[];
                commentIds?: string[];
                eventId?: string;
            }>
        ) => {
            state.alertDialog = action.payload;
        },
        updateAddTaskDialog: (
            state,
            action: PayloadAction<{
                isOpen: boolean;
                title?: string;
                checklistListIds?: string[];
                type?: ChecklistType.IROPS | ChecklistType.generic;
            }>
        ) => {
            state.addTaskDialog = action.payload;
        },
        updateChecklists: (state, action: PayloadAction<IChecklistItem[]>) => {
            state.checklists = action.payload;
        },
        updateChecklistTasks: (state, action: PayloadAction<{ checklistId: string; tasks: IChecklistTask[] }>) => {
            const { checklistId, tasks } = action.payload;
            const targetChecklist = state.checklists.find((checklist) => checklist.checklistId === checklistId);
            targetChecklist.tasks = tasks;
        },
        updateQnA: (state, action: PayloadAction<{ [key: string]: string }>) => {
            const qna = action.payload;
            state.eventDetail.qna = qna;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getChecklistEventsThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(getChecklistEventsThunk.fulfilled, (state, action) => {
                state.isChecklistLoading = false;
                state.checklistEvents = action.payload.results;
            })
            .addCase(getChecklistEventsThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(getChecklistsByIdThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(getChecklistsByIdThunk.fulfilled, (state, action) => {
                state.isChecklistLoading = false;
                state.checklists = action.payload.results
                    .sort((a: IChecklistItem, b: IChecklistItem) => {
                        return a.seq - b.seq;
                    })
                    .map((item: IChecklistItem) => {
                        // handle sorting logic
                        const sortTasks = item.tasks.sort((a: IChecklistTask, b: IChecklistTask) => {
                            return a.seq - b.seq;
                        });
                        item.tasks = sortTasks;
                        return item;
                    });
            })
            .addCase(getChecklistsByIdThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(getChecklistTaskByIdThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(getChecklistTaskByIdThunk.fulfilled, (state, action) => {
                state.isChecklistLoading = false;
                state.taskDetail = action.payload;
            })
            .addCase(getChecklistTaskByIdThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(getEventByIdThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(getEventByIdThunk.fulfilled, (state, action) => {
                state.isChecklistLoading = false;
                const currentItem = action.payload;
                const eventDetail = currentItem.isDraft
                    ? { ...currentItem.draftObj, eventId: currentItem.eventId }
                    : currentItem;
                state.eventDetail = eventDetail;
            })
            .addCase(getEventByIdThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateTaskThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(updateTaskThunk.fulfilled, (state, action) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateTaskThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateChecklistThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(updateChecklistThunk.fulfilled, (state, action) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateChecklistThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateIRopsMeetingThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(updateIRopsMeetingThunk.fulfilled, (state, action) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateIRopsMeetingThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(sendMeetingThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(sendMeetingThunk.fulfilled, (state, action) => {
                state.isChecklistLoading = false;
            })
            .addCase(sendMeetingThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateEventThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(updateEventThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateEventThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(createEventThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(createEventThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(createEventThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(reportChecklistIRopsThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(reportChecklistIRopsThunk.fulfilled, (state, { payload, meta }) => {
                const { exportPdf } = meta.arg;
                if (exportPdf) {
                    const { blob, header } = payload;
                    const encodedFileName = header['content-disposition']?.split('attachment;filename=')[1];
                    const fileName = decodeURI(encodedFileName);
                    downloadBlob({
                        blob,
                        fileName,
                    });
                }
                state.isChecklistLoading = false;
            })
            .addCase(reportChecklistIRopsThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(deleteChecklistAndTaskThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(deleteChecklistAndTaskThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(deleteChecklistAndTaskThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(sendChecklistObsMessageThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(sendChecklistObsMessageThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(sendChecklistObsMessageThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(copyTeamMeetingThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(copyTeamMeetingThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(copyTeamMeetingThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(addNewTasksThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(addNewTasksThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(addNewTasksThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(addNewChecklistThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(addNewChecklistThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(addNewChecklistThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(saveCommentThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(saveCommentThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(saveCommentThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(deleteCommentsThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(deleteCommentsThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(deleteCommentsThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(getCommentsThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(getCommentsThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(getCommentsThunk.fulfilled, (state, action) => {
                state.commentsList = action.payload.comments;
                state.isChecklistLoading = false;
            })
            .addCase(updateSequenceThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateSequenceThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateSequenceThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(updateQNAThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateQNAThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateQNAThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(closeTasksThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(closeTasksThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(closeTasksThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(updateEventStatusThunk.rejected, (state) => {
                state.isChecklistLoading = false;
            })
            .addCase(updateEventStatusThunk.pending, (state) => {
                state.isChecklistLoading = true;
            })
            .addCase(updateEventStatusThunk.fulfilled, (state) => {
                state.isChecklistLoading = false;
            });
    },
});

export const selectChecklist = (state: RootState) => state.checklist;
export const {
    selectEvent,
    updateTaskForm,
    updateTeamsMeetingForm,
    updateIrOpsReportForm,
    updateAlertDialog,
    updateAddTaskDialog,
    updateChecklistEventForm,
    updateChecklistForm,
    updateChecklists,
    updateChecklistTasks,
    updateBombThreatForm,
    updateQnA,
} = checklistSlice.actions;
export default checklistSlice.reducer;
